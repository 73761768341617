<template>
  <div class="flex flex-col overflow-y-auto w-full">
    <p class="font-bold text-xl font-SourceSansPro text-darkblue">
      {{ $t('notifications.deliveryMethod') }}
    </p>
    <div class="text-notify text-xs" v-if="!hasCheck" style="margin: -2px 0 -16px 0">
      {{ $t('notifications.chooseDeliveryType') }}
    </div>
    <div class="flex py-5 border-b border-dividerColor">
      <skif-checkbox
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        v-model="notification.is_show_online"
        :error="!hasCheck"
      ></skif-checkbox>
      <div class="flex flex-col relative ml-10 flex-grow">
        <div
          class="font-semibold text-base font-SourceSansPro transition-colors duration-300"
          :class="notification.is_show_online ? 'text-darkblue' : 'text-annotationColor'"
        >
          {{ $t('notifications.online') }}
        </div>
        <el-select
          class="mt-3 inputs"
          :disabled="isAllowedCreate && role !== 'EDITOR'"
          v-model="notification.sound.value"
        >
          <el-option
            v-for="(item, index) in unit_sounds"
            :key="index"
            :label="item.value"
            :value="item.value"
            placeholder="select"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="flex py-5 border-b border-dividerColor">
      <skif-checkbox
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        v-model="notification.isdeliveryemail"
        :error="!hasCheck || validateEmail"
      ></skif-checkbox>
      <div class="flex flex-col relative ml-10 flex-grow">
        <div
          class="font-semibold text-base font-SourceSansPro leading-5 transition-colors duration-300"
          :class="notification.isdeliveryemail ? 'text-darkblue' : 'text-annotationColor'"
        >
          {{ $t('notifications.byEmail') }}
        </div>
        <el-select
          class="mt-3 inputs"
          :disabled="isAllowedCreate && role !== 'EDITOR'"
          v-model="notification.delivery_emails"
          filterable="filterable"
          multiple="multiple"
          allow-create="allow-create"
          default-first-option="default-first-option"
          :placeholder="$t('notifications.email.placeHolder')"
          :class="{ 'input-error': validateEmail }"
        >
          <el-option
            v-for="item in usersWithEmail"
            :key="item.id"
            :label="item.email"
            :value="item.email"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right" class="ml-2 email-grey text-right">
              {{ item.email }}
              <span style="float: right" v-if="item.is_approved" class="ml-1">
                <EmailIcon />
              </span>
              <span style="float: right" v-else class="ml-2">
                <UnverfiedIcon :color="item.is_approved ? 'normal' : 'danger'" />
              </span>
            </span>
          </el-option>
        </el-select>
        <transition name="el-zoom-in-top">
          <div class="text-notify text-xs top-full left-0 absolute" v-if="validateEmail">
            {{ $t('notifications.chooseEmail') }}
          </div>
        </transition>
        <div>
          <div class="mt-1 inputs">
            <span class="mr-1"><UnverfiedIcon :color="'danger'" /></span>
            <span class="unverfied-text">
              {{ $t('notifications.text.confirmation') }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="flex py-5 border-b border-dividerColor">
      <skif-checkbox
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        v-model="notification.isdeliverysms"
        :error="!hasCheck || validatePhone"
      />
      <div class="flex flex-col relative ml-10 flex-grow">
        <div
          class="font-semibold text-base font-SourceSansPro leading-5 transition-colors duration-300"
          :class="notification.isdeliverysms ? 'text-darkblue' : 'text-annotationColor'"
        >
          {{ $t('notifications.bySms') }}
        </div>
        <el-select
          v-model="notification.delivery_sms"
          class="mt-3 inputs"
          filterable="filterable"
          multiple="multiple"
          allow-create="allow-create"
          default-first-option="default-first-option"
          :disabled="isAllowedCreate && role !== 'EDITOR'"
          :placeholder="$t('notifications.phone.placeHolder')"
          :class="{ 'input-error': validatePhone }"
        >
          <el-option
            v-for="(item, index) in usersPhone"
            :key="index"
            :label="item.phone"
            :value="item.phone"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">
              {{ item.phone }}
              <span v-if="item.is_approved_phone" style="float: right" class="ml-1">
                <i class="el-icon-phone"></i>
              </span>
              <span style="float: right" v-else class="ml-2">
                <UnverfiedIcon :color="item.is_approved_phone ? 'normal' : 'danger'" />
              </span>
            </span>
          </el-option>
        </el-select>
        <transition name="el-zoom-in-top">
          <div class="text-notify text-xs top-full left-0 absolute" v-if="validatePhone">
            {{ $t('notifications.choosePhone') }}
          </div>
        </transition>
      </div>
    </div>
    <div class="flex py-5 border-b border-dividerColor">
      <skif-checkbox
        v-model="notification.isdeliverypush"
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        :error="!hasCheck || validatePush"
      />
      <div class="flex flex-col relative ml-10 flex-grow">
        <div
          class="font-semibold text-base font-SourceSansPro leading-5 transition-colors duration-300"
          :class="notification.isdeliveryemail ? 'text-darkblue' : 'text-annotationColor'"
        >
          {{ $t('notifications.byPush') }}
        </div>
        <el-select
          v-model="notification.delivery_push"
          filterable="filterable"
          multiple="multiple"
          class="mt-3 inputs"
          :disabled="isAllowedCreate && role !== 'EDITOR'"
          :placeholder="$t('notifications.push.placeHolder')"
          :class="{ 'input-error': validatePush }"
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.name || item.email"
            :value="item.id"
          >
            <div>
              <template v-if="item.name && item.email">
                {{ item.name }}
                <small>({{ item.email }})</small>
              </template>
              <template v-else-if="item.email">
                {{ item.email }}
              </template>
            </div>
          </el-option>
        </el-select>
        <transition name="el-zoom-in-top">
          <div v-if="validatePush" class="text-notify text-xs top-full left-0 absolute">
            {{ $t('notifications.chooseUser') }}
          </div>
        </transition>
      </div>
    </div>
    <div class="flex py-5 border-b border-dividerColor">
      <skif-checkbox
        v-model="notification.isdeliverytelegram"
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        :error="!hasCheck || validateTelegram"
      />
      <div class="flex flex-col relative ml-10 flex-grow">
        <div
          class="font-semibold text-base font-SourceSansPro leading-5 transition-colors duration-300"
          :class="
            notification.isdeliverytelegram ? 'text-darkblue' : 'text-annotationColor'
          "
        >
          {{ $t('notifications.byTelegram') }}
        </div>
        <el-select
          v-model="notification.delivery_telegram"
          filterable="filterable"
          multiple="multiple"
          class="mt-3 inputs"
          :disabled="isAllowedCreate && role !== 'EDITOR'"
          :placeholder="$t('notifications.telegram.placeHolder')"
          :class="{ 'input-error': validateTelegram }"
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            v-if="item.telegram_chat_id"
            :label="`${item.name || item.email}(${item.telegram_chat_id})`"
            :value="item.telegram_chat_id"
          >
            <div>
              <template
                >{{ item.name || item.email }}
                <small>({{ item.telegram_chat_id }})</small></template
              >
            </div>
          </el-option>
        </el-select>
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="validateTelegram"
          >
            {{ $t('notifications.chooseUser') }}
          </div>
        </transition>
      </div>
    </div>

    <!-- commands start -->
    <!-- TODO:checkbox validation -->
    <div class="flex py-5 border-b border-dividerColor">
      <skif-checkbox
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        v-model="notification.isdeliverycommand"
        :error="!hasCheck"
      ></skif-checkbox>
      <div class="flex flex-col relative ml-10 flex-grow">
        <div
          class="font-semibold text-base font-SourceSansPro transition-colors duration-300"
          :class="
            notification.isdeliverycommand ? 'text-darkblue' : 'text-annotationColor'
          "
        >
          {{ $t('notifications.commands.send_command') }}
        </div>
        <transition name=" el-zoom-in-bottom">
          <Commands
            v-if="notification.isdeliverycommand"
            :notification="notification"
            @setDeliveryCommands="setDeliveryCommands"
            @isCommandEmpty="isCommandEmpty"
          />
        </transition>
      </div>
    </div>
    <div class="flex py-3 mt-6 items-center">
      <skif-checkbox
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        v-model="notification.isevent"
      ></skif-checkbox>
      <div
        class="font-semibold text-base ml-10 font-SourceSansPro transition-colors duration-300"
        :class="notification.isevent ? 'text-darkblue' : 'text-annotationColor'"
      >
        {{ $t('notifications.asEvent') }}
      </div>
    </div>
    <div class="flex py-3 items-center">
      <skif-checkbox
        :disabled="isAllowedCreate && role !== 'EDITOR'"
        v-model="notification.isviolation"
      ></skif-checkbox>
      <div
        class="font-semibold text-base ml-10 font-SourceSansPro transition-colors duration-300"
        :class="notification.isviolation ? 'text-darkblue' : 'text-annotationColor'"
      >
        {{ $t('notifications.asViolation') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { notificationsApi } from '@/api'
import { Commands } from '@/components/notifications/components/commands'

export default {
  components: {
    EmailIcon: () => import('../icons/EmailIcon.vue'),
    UnverfiedIcon: () => import('../icons/UnverfiedIcon.vue'),
    Commands
  },
  props: [
    'notification',
    'phoneMissing',
    'emailMissing',
    'pushMissing',
    'telegramMissing',
    'commandsMissing'
  ],
  computed: {
    ...mapGetters('users', ['usersWithEmail', 'usersWithPhone']),
    ...mapGetters('dictionary', ['unit_sounds']),
    ...mapGetters('login', ['role', 'disabledRole', 'notRole']),
    ...mapState('users', {
      users: state => state.users
    }),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    },
    usersPhone() {
      const arr = this.users.filter(el => el.phone)
      return arr.map(el => {
        return {
          phone: el.phone,
          name: el.name,
          is_approved_phone: el.is_approved_phone
        }
      })
    },

    validateCommands: {
      get() {
        return this.commandsMissing
      },
      set(newValue) {
        this.$emit('updateCommandsMissing', newValue)
      }
    },
    validateEmail: {
      get() {
        return this.emailMissing
      },
      set(newValue) {
        this.$emit('updateEmailMissing', newValue)
      }
    },
    validatePhone: {
      get() {
        return this.phoneMissing
      },
      set(newValue) {
        this.$emit('updatePhoneMissing', newValue)
      }
    },
    validatePush: {
      get() {
        return this.pushMissing
      },
      set(newValue) {
        this.$emit('updatePushMissing', newValue)
      }
    },
    validateTelegram: {
      get() {
        return this.telegramMissing
      },
      set(newValue) {
        this.$emit('updateTelegramMissing', newValue)
      }
    },
    hasCheck() {
      const n = this.notification
      return (
        n.isdeliveryemail ||
        n.isdeliverysms ||
        n.isdeliverypush ||
        n.isdeliverytelegram ||
        n.is_show_online ||
        n.isdeliverycommand
      )
    }
  },

  watch: {
    'notification.sound.value': function (val) {
      const sound = this.unit_sounds.filter(sound => sound.value === val)

      if (sound[0].key !== 'no_sound') {
        notificationsApi.getNotificationSound(
          `${val}.mp3`,
          response => {
            const mp3 = new Blob([response.data], { type: 'audio/mp3' })
            const url = window.URL.createObjectURL(mp3)
            const audio = new Audio(url)
            audio.load()
            audio.play()

            this.notification.sound = {
              key: sound[0].key,
              value: sound[0].value
            }
          },
          error => {
            console.log('error', error)
          }
        )
      } else {
        this.notification.sound = {
          key: 'no_sound',
          value: this.$t('notifications.no_sound')
        }
      }
    },
    // email
    'notification.isdeliveryemail': function (val) {
      this.validateEmail = val && this.notification.delivery_emails.length < 1
    },
    'notification.delivery_emails': function (val) {
      this.validateEmail = val.length < 1 && this.notification.isdeliveryemail
    },
    // commands
    'notification.isdeliverycommand': function (val) {
      this.validateCommands = val && this.notification.isdeliverycommand
    },
    // phone
    'notification.isdeliverysms': function (val) {
      this.validatePhone = val && this.notification.delivery_sms.length < 1
    },
    'notification.delivery_sms': function (val) {
      this.validatePhone = val.length < 1 && this.notification.isdeliverysms
    },
    // push
    'notification.delivery_push': function (val) {
      this.validatePush = val.length < 1 && this.notification.isdeliverypush
    },
    'notification.isdeliverypush': function (val) {
      this.validatePush = val && this.notification.delivery_push.length < 1
    },
    // telegram
    'notification.delivery_telegram': function (val) {
      this.validateTelegram = val.length < 1 && this.notification.isdeliverytelegram
    },
    'notification.isdeliverytelegram': function (val) {
      this.validateTelegram = val && this.notification.delivery_telegram.length < 1
    }
  },
  methods: {
    setDeliveryCommands(val) {
      this.notification.delivery_commands = val
      // delete empty commands
      this.notification.delivery_commands = this.notification.delivery_commands.filter(
        commandItem => commandItem.command !== ''
      )
    },
    isCommandEmpty(val) {
      this.$emit('isCommandEmpty', val)
    }
  }
}
</script>
<style scoped lang="scss">
.email-grey {
  color: #8492a6;
  font-size: 13px;
}

.notVerify.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  content: url(../icons/CheckedRed.svg);
}
.unverfied-text {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* or 17px */

  color: #5b6d79;
  word-wrap: break-word;
}
.inputs {
  width: 321px;
}
</style>
