<template>
  <div>
    <transition :name="'dialog-fade'">
      <div v-if="isCommandEmpty" class="warning-wrapper">
        <h1>{{ $t('notifications.commands.warning') }}</h1>
      </div>
    </transition>
    <div v-for="(terminalType, index) in terminalTypes" :key="index" class="main">
      <section class="header-container">
        <div class="header">
          <span class="title">{{ terminalType.value }}</span>
          <span class="link subtitle">
            {{ makeObjectsCount(unitsByType[terminalType.key]) }}
            {{ unitsByType[terminalType.key] }}
          </span>
<!--          <el-dropdown trigger="click">-->
<!--            <span class="link subtitle">-->
<!--              {{ makeObjectsCount(unitsByType[terminalType.key]) }}-->
<!--              {{ unitsByType[terminalType.key] }}-->
<!--            </span>-->
<!--            <el-dropdown-menu class="command-dropdown" slot="dropdown">-->
<!--              <div class="filter-input">-->
<!--                <SearchInput :filterText="filterText" @updateFilter="updateFilter" />-->
<!--              </div>-->
<!--              <el-dropdown-item-->
<!--                v-for="(unit, index) in terminalTypeUnitsMap[terminalType.key]"-->
<!--                :key="index"-->
<!--              >-->
<!--                <div class="item">-->
<!--                  <span class="item__name">-->
<!--                    {{ unit }}-->
<!--                  </span>-->
<!--                  <span class="item__key">-->
<!--                    {{ terminalType.key }}-->
<!--                  </span>-->
<!--                </div>-->
<!--              </el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
        </div>
        <div class="templates-container">
          <el-dropdown trigger="click" @command="selectCommandTemplate(index, $event)">
            <span class="link">
              {{ $t('notifications.commands.select_template') }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu class="command-dropdown" slot="dropdown">
              <div class="filter-input">
                <SearchInput :filterText="filterText" @updateFilter="updateFilter" />
              </div>
              <el-dropdown-item
                v-for="(command, index) in filteredCommands(terminalType)"
                :key="index"
                :command="command"
              >
                {{ command.name }}
              </el-dropdown-item>
              <el-dropdown-item @click.native.stop="() => {}" v-if="filteredCommands(terminalType).length === 0">
                {{ $t('notifications.commands.template_empty') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </section>

      <section>
        <el-input
          @input="handleCommand(index, $event)"
          class="template-input"
          :placeholder="$t('command-terminal')"
          type="text"
          v-model="inputValues[index]"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { pluralize } from '@/utils/helpers'
import SearchInput from '../SearchInput/SearchInput.vue'

export default {
  name: 'Commands',
  props: ['notification'],
  components: {
    SearchInput
  },
  computed: {
    ...mapGetters('notifications', ['notificationUnitsIds']),
    ...mapState('units', {
      units: state => state.defaultUnits
    }),
    ...mapGetters('commands', ['commandsTemplate']),
    isCommandEmpty() {
      const isEmpty =
        !this.inputValues.some(value => value !== '')
      this.$emit('isCommandEmpty', isEmpty)
      return isEmpty
    },

  },
  watch: {
    notificationUnitsIds: {
      handler() {
        this.getSelectedUnits()
      },
      deep: true
    }
  },
  data() {
    return {
      terminalTypes: [],
      inputValues: [],
      deliveryCommands: [],
      unitsByType: [],
      filterText: '',
      isObjectsList: false,
      unitsList: [],
      terminalTypeUnitsMap: {}
    }
  },
  methods: {
    filteredCommands(type) {
      if (!this.commandsTemplate) {
        return []
      }

      return (
        this.commandsTemplate.length &&
        this.commandsTemplate.filter(command => {
          return (
            command.terminal_type.includes(type.key)
          )
        })
      )
    },
    updateFilter(value) {
      this.filterText = value
    },
    makeObjectsCount(count) {
      return pluralize([
        this.$t('notifications.one.object'),
        this.$t('notifications.more.object'),
        this.$t('notifications.many.object')
      ])(count)
    },
    handleCommand(index, $event) {
      // update the input value and the delivery command
      this.inputValues[index] = $event
      if (this.deliveryCommands[index]) {
        this.deliveryCommands[index].command = $event
      } else {
        this.deliveryCommands.push({
          command: $event,
          terminal_type_key: this.terminalTypes[index].key
        })
      }
      // clear the search input
      this.filterText = ''
      this.$emit('setDeliveryCommands', this.deliveryCommands)
    },
    selectCommandTemplate(index, command) {
      if(!command) return
      // Set the input value to the selected command's template
      this.$set(this.inputValues, index, command.command_template)
      // Set the terminal type to the selected command's terminal type
      const deliveryCommand = {
        terminal_type_key: this.terminalTypes[index].key,
        command: this.inputValues[index]
      }

      this.deliveryCommands[index] = deliveryCommand
      // clear the search input
      this.filterText = ''
      // Set the delivery commands
      this.$emit('setDeliveryCommands', this.deliveryCommands)
    },
    getSelectedUnits() {
      // Get selected units based on the units that we select in the main tab
      const selectedUnits = this.units.filter(unit =>
        this.notificationUnitsIds.includes(unit.id)
      )

      //  Get the terminal from each selected unit, we use set to remove duplicates
      const uniqueTerminalStrings = new Set()
      selectedUnits.forEach(unit => {
        const terminalKey = unit.terminal_type.key
        const unitName = unit.name
        if (!this.terminalTypeUnitsMap[terminalKey]) {
          this.terminalTypeUnitsMap[terminalKey] = []
        }
        this.terminalTypeUnitsMap[terminalKey].push(unitName)
        const terminalString = JSON.stringify(unit.terminal_type)
        uniqueTerminalStrings.add(terminalString)
      })

      //  Convert the set to an array
      this.terminalTypes = Array.from(uniqueTerminalStrings, str => JSON.parse(str))

      // Make units array by type
      this.terminalTypes.map(terminal => {
        this.unitsByType[terminal.key] = 0
      })

      const selectedUnitsIds = this.$store.getters['notifications/notificationUnitsIds']

      this.units.map(unit => {
        if (
          unit.terminal_type.key in this.unitsByType &&
          selectedUnitsIds.includes(unit.id)
        ) {
          this.unitsByType[unit.terminal_type.key]++
        }
      })
      console.log('uniqueTerminalStrings', this.terminalTypeUnitsMap)
    },
    getCommandsTemplates() {
      this.$store.dispatch('commands/getCommandTemplate')
    }
  },
  beforeMount() {
    this.getSelectedUnits()
    this.getCommandsTemplates()
    // Set the delivery commands if we edit notification
    if (this.notification.delivery_commands.length > 0) {
      this.deliveryCommands = this.notification.delivery_commands
      this.inputValues = this.notification.delivery_commands.map(
        command => command.command
      )
    }
  }
}
</script>

<style scoped lang="scss">
.warning-wrapper {
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background-color: #ffdbdb;
  color: #b62424;
  font-weight: 400;
  font-size: 12px;
  padding: 10px;
  border-radius: 12px;
}
.main {
  font-family: Source Sans Pro;
  border-bottom: 1px solid #edeff3;
  margin-bottom: 20px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding-bottom: 10px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title {
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        color: #595959;
        margin-bottom: 5px;
      }
      .subtitle {
        font-size: 10px;
        color: #bec7d3;
        font-weight: 600;
        color: #3a7cca;
        cursor: pointer;
      }
    }
    .templates-container {
      display: flex;
      margin-bottom: 8px;
      .link {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #3a7cca;
      }
    }
  }
}
.filter-input {
  margin: 10px;
  border-bottom: 1px solid #edeff3;
  padding-bottom: 8px;
}
:deep(.el-dropdown-menu__item) {
  word-break: break-all;
}

.el-dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-color: rgba(51, 51, 51, 0.3) #ffffff;
  scrollbar-width: thin;
  max-width: 400px;
  min-width: 400px;
  margin: 0;
  padding: 0;
  .item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #edeff3;
    padding: 0;
    &__name {
      font-size: 14px;
      font-weight: 400;
      color: #171717;
    }
    &__key {
      font-size: 12px;
      color: #a6aeb8;
    }
  }
}
::v-deep(.el-input input) {
  border: none;
  border-radius: 10px;
  background-color: #edeff3;
  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #a6aeb8;
  }
}

.command-dropdown {
  border-radius: 12px;
}
</style>
